export default [
  {
    title: 'Properties',
    route: { name: 'complex.property' },
    icon: 'propertyIcon-2',
    showCount: true,
    getterName: 'complex/complexPropertiesTotal'
  },
  {
    title: 'Media',
    route: { name: 'complex.media' },
    icon: 'gallery',
  },
  {
    title: '3D view',
    route: { name: 'complex.ThreeDView' },
    icon: '3dcube',
  }
]
