<template>
  <div class="full-height">
    <LayoutVerticalCustom
      :verticalNavbarList="verticalNavbarList"
      :name="complexDetails ? complexDetails.name : ''"
      :thumbnail="complexDetails ? complexDetails.thumbnail : ''"
      @openEditSidebar="openEditSidebar"
    />

    <div>
      <b-sidebar
        id="edit-project-sidebar-id"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <div class="fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
            <span class="fd-text-theme-2 fd-text-base">{{ $t('global.setting') }}</span>
            <div @click="openEditSidebar" class="fd-cursor-pointer">
              <SvgLoader :name="'close'" />
            </div>

          </div>
          <div class="fd-p-3">
            <div class="project-image fd-mx-auto fd-overflow-hidden">
              <img :src="getNewImageUrl ? getNewImageUrl : complexDetails.thumbnail" alt="" class="object-cover" />
            </div>
            <span class="fd-text-xs fd-text-theme-3 fd-block fd-text-center fd-mt-2">{{ $t('global.allowedFileTypes') }}</span>
            <div class="fd-flex fd-items-center fd-justify-center fd-mt-5 fd-mx-auto" style="width: 231px;">
              <input type="file" ref="file" @change="uploadFile" style="display: none">
              <b-button block variant="primary" class="fd-mr-2" @click="upload">{{ $t('global.upload') }}</b-button>
              <b-button block variant="outline-primary" @click="resetFile" style="margin-top: 0 !important;">{{ $t('global.reset') }}</b-button>
            </div>
            <div class="fd-mt-14">
              <div>
                <label for="name" class="fd-text-xs fd-text-theme-9">{{ $t('global.name') }}</label>
                <b-form-input id="name" v-model="form.name" type="text" />
              </div>
              <div class="fd-mt-3 fd-text-theme-9">
                <label for="description">{{ $t('global.description') }}</label>
                <b-form-textarea id="description" v-model="form.description" rows="10" max-rows="10" />
              </div>
              <div class="fd-flex fd-items-center fd-justify-center fd-mt-5">
                <b-button @click="update" block variant="primary" class="fd-mr-2">{{ $t('global.save') }}</b-button>
                <b-button @click="openEditSidebar" block variant="outline-primary" style="margin-top: 0 !important;">{{ $t('global.cancel') }}</b-button>
              </div>
            </div>
          </div>
        </template>

      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/components/global/Sidebar";
import verticalNavbar from "@/views/components/ProjectManagement/verticalComplexNavbar";
import { mapGetters } from 'vuex'
import { BSidebar, BButton, BFormInput, BFormTextarea, BBreadcrumb } from 'bootstrap-vue'
import LayoutVerticalCustom from "@/layouts/vertical/LayoutVerticalCustom";

export default {
  name: "Complex",
  components: {LayoutVerticalCustom, Sidebar, BSidebar, BButton, BFormInput, BFormTextarea, BBreadcrumb},
  data() {
    return {
      verticalNavbarList: [],
      image: null,
      newImageUrl: null,
      thumbnail: null,
      form: {
        name: '',
        description: ''
      }
    }
  },
  created() {
    this.getComplexDetails()
    this.verticalNavbarList = verticalNavbar
  },
  computed: {
    ...mapGetters({
      complexDetails: 'complex/complexDetails'
    }),
    getNewImageUrl() {
      return this.newImageUrl
    }
  },
  methods: {
    openEditSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'edit-project-sidebar-id')
    },
    getComplexDetails() {
      this.$store.dispatch('complex/getComplexDetails', this.$route.params.id).then(() => {
        this.form.name = this.complexDetails.name
        this.thumbnail = this.complexDetails.thumbnail
        this.form.description = this.complexDetails.description
      })
    },
    update() {
      let newData = this.form
      newData.thumbnail = this.image
      this.$store.dispatch('complex/updateComplex', {
        id: this.$route.params.id,
        data: newData
      })
        .then(() => {
          this.getComplexDetails()
          this.openEditSidebar()
        })
    },
    upload() {
      this.$refs.file.click()
    },
    uploadFile() {
      this.image = this.$refs.file.files[0]
      this.newImageUrl = URL.createObjectURL(this.image)
    },
    resetFile() {
      this.image = null
      this.newImageUrl = null
    },
  }
}
</script>

<style lang="scss" scoped>

.project-image {
  width: 231px;
  height: 174px;
  border-radius: 3px;
  img {
    width: 231px;
    height: 174px;
  }
}
</style>